
























































import { mapState } from 'vuex'
import Mixin from './common/Mixins'
import User from './common/User.vue'
import { formatDate } from '@/utils'

export default Mixin.extend({
  name: 'Kanban',
  props: {
    task: Object as () => UserTask,
  },
  data() {
    return {
      now: new Date(),
    }
  },
  components: {
    User,
  },
  computed: {
    ...mapState(['taskTypes', 'myId']),
  },
  methods: {
    isArray: Array.isArray,
    formatDate,
  },
})
