








import Vue from 'vue'
import Filters from './components/Filters.vue'
import Kanban from './components/Kanban.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Filters,
    Kanban,
  },
  provide() {
    return {
      spaParent: this.$parent || {},
    }
  },
  data() {
    const props = (this.$parent?.props as WidgetProps) || {}
    return {
      props,
    }
  },
  beforeMount() {
    this.$store.commit('reset')
  },
})
