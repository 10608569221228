



import Vue from 'vue'
import { mapStateTyped } from '@/store'
import { history, SearchParams } from '@netvision/lib-history'

export default Vue.extend({
  name: 'UserTaskDialog',
  inject: ['spaParent'],
  props: {
    options: Object,
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  computed: {
    ...mapStateTyped([
      'currentUserTask',
      'permissionScopes',
      'userTaskMetadata',
    ]),
  },
  mounted() {
    this.unmount = this.mountWidget()
  },
  beforeDestroy() {
    this.unmount()
  },
  methods: {
    mountWidget(): () => void {
      let ref = this.$refs[`user-task-${this.currentUserTask.id}`]
      ref = Array.isArray(ref) ? ref[0] : ref
      // @ts-ignore
      const widget = this.spaParent.areas.find(
        (e: any) => e.name === 'UserTask',
      )
      if (widget && ref) {
        // @ts-ignore
        return this.spaParent.mountChildren(
          ref,
          widget.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                // @ts-ignore
                areas: this.spaParent.areas,
                meta: this.userTaskMetadata,
                permissionScopes: this.permissionScopes,
                userTask: this.currentUserTask,
                onClose: this.deleteTaskFromURL,
              },
            }
          }),
        )
      } else {
        this.deleteTaskFromURL()
        return () => {}
      }
    },
    deleteTaskFromURL() {
      const query = SearchParams.parse(history.location.search)
      delete query.task
      history.push({
        search: SearchParams.stringify(query),
      })
    },
  },
})
