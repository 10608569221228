import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import Vuex from 'vuex'
import Store from './store'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ProgressBar from 'primevue/progressbar'
import Tooltip from 'primevue/tooltip'
import Checkbox from 'primevue/checkbox'
import App from './App.vue'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

Vue.component('ProgressBar', ProgressBar)
Vue.component('InputText', InputText)
Vue.component('Checkbox', Checkbox)
Vue.component('Button', Button)
Vue.component('Toast', Toast)

Vue.prototype.$eventBus = new Vue()

Vue.use(VueI18n)
Vue.use(Vuex)
Vue.use(ToastService)

Vue.directive('tooltip', Tooltip)
// Vue.use(VueResizeObserver)

Vue.config.productionTip = false

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const store = new Vuex.Store(Store)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    i18n,
    store,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
