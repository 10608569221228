






























































































































































import Mixin from './common/Mixins'
import TaskCard from './TaskCard.vue'
import UserTaskDialog from './common/UserTaskDialog.vue'
import EmptyTasks from './common/EmptyTasks.vue'
import { history, SearchParams } from '@netvision/lib-history'
import draggable from 'vuedraggable'
import {
  getUserInfo,
  createCamerasConnection,
  listEntities,
  getSharedNotificationSocket,
} from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'
import Settings from './Settings.vue'
import { debounce, makeDictFromArray } from '@/utils'

const INFINIT_SCROLL_STEP = 10
const STATUSES = ['board', 'settings'] as const
type BoardStatuses = typeof STATUSES[number]

export default Mixin.extend({
  name: 'Kanban',
  props: {
    title: String,
    steps: Array as () => WidgetProps['steps'],
  },
  components: {
    TaskCard,
    UserTaskDialog,
    draggable,
    EmptyTasks,
    Settings,
  },
  data() {
    return {
      status: 'board' as BoardStatuses,
      draggedEntity: null as UserTask | null,
      allTasks: [] as UserTask[],
      stepsChecked: {} as Record<string, boolean>,
      socketListenerUnsubscribe: () => {},
      unlistenHistory: () => {},
      disabledInfiniteScrollColumn: {} as Record<Step['title'], boolean>,
      infiniteScrollColumnLoading: {} as Record<Step['title'], boolean>,
    }
  },
  watch: {
    visibleSteps(val: Step[], prevVal: Step[]) {
      /*
      Запрашивать только если добавились новые колонки и раз в секунду,
      на случай быстрых кликов
      */
      const diff = val.filter((step) => !prevVal.includes(step))

      debounce(
        async () => {
          this.allTasks = this.joinUserTasks(
            await this.fetchUserTasks(diff.map(({ title }) => title)),
          )
        },
        1000,
        'userTasks',
      )
    },
  },
  computed: {
    ...mapStateTyped(['currentUserTask', 'loading']),
    visibleSteps(): Step[] {
      return this.steps.filter(({ title }) => {
        return this.stepsChecked[title]
      })
    },
    columns() {
      const columns = [] as (Step & { userTasks: UserTask[] })[]
      for (const step of this.visibleSteps) {
        const userTasks = this.allTasks.filter(({ status }) =>
          step.statuses.includes(status),
        )
        columns.push({ ...step, userTasks })
      }
      return columns
    },
  },
  async beforeMount() {
    const {
      entity: userTaskMetadata,
    } = (await createCamerasConnection().v2.getEntity({
      id: 'EntityTypeMetadata:UserTask',
      type: 'EntityTypeMetadata',
      keyValues: true,
    })) as { entity: UserTaskMetadata }
    this.$store.commit('setValue', ['userTaskMetadata', userTaskMetadata])
    this.$store.commit('setValue', [
      'taskTypes',
      makeDictFromArray(
        userTaskMetadata?.viewCard?.dictionaries?.userTaskTypes || {},
        'title',
      ),
    ])
    // Mixing locales
    if (userTaskMetadata?.localeRu) {
      // @ts-ignore
      this.$i18n.vm.messages[this.$i18n.locale] = {
        ...this.$i18n.messages[this.$i18n.locale],
        ...userTaskMetadata?.localeRu,
      }
    }

    const savedStepsChecked = JSON.parse(
      localStorage.getItem(`savedStepsChecked${history.location.pathname}`) ||
        'null',
    )
    if (savedStepsChecked) {
      this.stepsChecked = savedStepsChecked
    } else {
      this.stepsChecked = this.steps.reduce((acc, { title, isHidden }) => {
        acc[title] = isHidden === undefined || !isHidden
        return acc
      }, {} as Record<string, boolean>)
    }
  },
  methods: {
    async createSubscription() {
      this.socketListenerUnsubscribe = await getSharedNotificationSocket().addListener(
        'UserTask',
        (entity: any) => {
          const userTaskIndex = this.allTasks.findIndex(
            ({ id }) => id === entity.id,
          )
          if (userTaskIndex !== -1) {
            this.$set(this.allTasks, userTaskIndex, entity)
          }
        },
      )
    },
    async onScroll({ target }: Event, columnName: string) {
      const tagetHTML = target as HTMLElement
      if (
        !this.infiniteScrollColumnLoading[columnName] &&
        !this.disabledInfiniteScrollColumn[columnName] &&
        tagetHTML &&
        tagetHTML.scrollTop + tagetHTML.clientHeight >=
          tagetHTML.scrollHeight - 10
      ) {
        const countOfTasks =
          this.columns.find(({ title }) => title === columnName)?.userTasks
            ?.length || 0
        this.infiniteScrollColumnLoading = {
          ...this.infiniteScrollColumnLoading,
          [columnName]: true,
        }
        try {
          const {
            results: entities,
          }: {
            results: UserTask[]
          } = await listEntities(createCamerasConnection(), {
            type: 'UserTask',
            orderBy: '!dateCreated',
            limit: INFINIT_SCROLL_STEP,
            offset: countOfTasks,
            q: `status==${columnName}`,
          })
          entities.length > 0 && (this.allTasks = this.joinUserTasks(entities))
          entities.length < INFINIT_SCROLL_STEP &&
            (this.disabledInfiniteScrollColumn = {
              ...this.disabledInfiniteScrollColumn,
              [columnName]: true,
            })
        } catch (error) {
          console.error(error)
        } finally {
          this.infiniteScrollColumnLoading = {
            ...this.infiniteScrollColumnLoading,
            [columnName]: false,
          }
        }
      }
    },
    async fetchUserTasks(steps: string[], q?: string): Promise<UserTask[]> {
      this.$store.commit('setValue', ['loading', true])
      const options = {
        limit: 15,
        type: 'UserTask',
        orderBy: '!dateCreated',
      } as any
      const results = await Promise.allSettled(
        steps.map((step) => {
          return listEntities(createCamerasConnection(), {
            ...options,
            q: q ? `${q};status==${step}` : `status==${step}`,
          })
            .then(({ results }) => results)
            .catch((error) => {
              console.error(error)
              return []
            })
        }),
      )
      this.disabledInfiniteScrollColumn = {}

      this.$store.commit('setValue', ['loading', false])
      return (
        results
          .filter(({ status }) => status === 'fulfilled')
          // @ts-ignore
          .map(({ value }) => value)
          .flat()
      )
    },
    startDrag(event: any) {
      const { item } = event
      this.draggedEntity =
        this.allTasks.find(({ id }) => id === item?.dataset?.id) || null
    },
    joinUserTasks(userTasks: UserTask[]) {
      const userTasksMap = userTasks.reduce((acc, userTask) => {
        acc[userTask.id] = userTask
        return acc
      }, {} as Record<string, UserTask>)
      const tasks = this.allTasks.map((task) => {
        const userTask = userTasksMap[task.id]
        if (userTask) {
          delete userTasksMap[task.id]
          return userTask
        } else {
          return task
        }
      })
      return [...tasks, ...Object.values(userTasksMap)]
    },
    async onDrop(event: any, { commandOnDrop, statuses }: Step) {
      const { item } = event
      const id = item?.dataset?.id
      if (id && commandOnDrop) {
        const userTaskIndex = this.allTasks.findIndex(
          ({ id: taskId }) => id === taskId,
        )
        createCamerasConnection()
          .v2.updateEntityAttributes(
            {
              id: id,
              type: 'UserTask',
              [commandOnDrop]: {},
            },
            {
              keyValues: true,
            },
          )
          .then(() => {
            userTaskIndex !== -1 &&
              this.$set(this.allTasks, userTaskIndex, {
                ...this.allTasks[userTaskIndex],
                status: statuses[0],
              })
          })
          .catch(() => {
            userTaskIndex !== -1 &&
              this.$set(this.allTasks, userTaskIndex, {
                ...this.allTasks[userTaskIndex],
              })
            this.errorToast({ message: this.$t('message.unacceptableMove') })
          })
      }
    },
    setHash(hash: string | null) {
      if (hash) {
        history.push({ search: history.location.search, hash })
      } else {
        history.push({ search: history.location.search })
      }
    },
    async reactOnTaskInURL() {
      const { task: taskId } = SearchParams.parse(history.location.search) as {
        task: Uuid | undefined
      }
      let task
      if (taskId) {
        task = this.allTasks.find(({ id }) => id === taskId)
        if (!task) {
          try {
            const { entity } = await createCamerasConnection().v2.getEntity({
              id: taskId,
              type: 'UserTask',
              keyValues: true,
            })
            entity && (task = entity) && this.joinUserTasks([entity])
          } catch (error) {
            console.error(error)
          }
        }
      }
      this.$store.commit('setValue', ['currentUserTask', task || null])
    },
  },
  async mounted() {
    const { userId } = await getUserInfo()
    const hash = history.location.hash.replace('#', '') as BoardStatuses | ''
    // @ts-ignore
    if (STATUSES.includes(hash) || hash === '') {
      this.status = hash === '' ? 'board' : hash
    }
    this.unlistenHistory = history.listen((location) => {
      let { hash } = location as { hash: string }
      hash = hash.replace('#', '')
      // @ts-ignore
      if (STATUSES.includes(hash) || hash === '') {
        this.status = hash === '' ? 'board' : (hash as BoardStatuses)
      }
      this.reactOnTaskInURL()
    })
    this.$store.commit('setValue', ['myId', userId])
    const visibleStepsTitles = Object.entries(this.stepsChecked)
      .filter(([_, val]) => val)
      .map(([key, _]) => key)
    this.allTasks = await this.fetchUserTasks(visibleStepsTitles)
    this.createSubscription()
    // If in search params we've got task id, open them immediately
    setTimeout(this.reactOnTaskInURL)
  },
  destroyed() {
    this.unlistenHistory()
    this.socketListenerUnsubscribe()
  },
})
