








































import Vue from 'vue'
import { dateTimeFormats } from '@/translations/dateTimeLocale'
import Calendar from 'primevue/calendar'

const DAY = 24 * 60 * 60 * 1000
const WEEK = DAY * 6 // because its rounded up

export default Vue.extend({
  name: 'CalendarField',
  props: {
    Calendar,
  },
  data() {
    return {
      dates: [new Date(), null] as [Date | null, Date | null],
      dateLocale: dateTimeFormats[this.$i18n.locale],
      toggled: {},
    }
  },
  computed: {
    datesButtons(): any[] {
      return [
        {
          label: this.$t('calendarButtons.yesterday'),
          onClick: this.yesterday,
        },
        {
          label: this.$t('calendarButtons.today'),
          onClick: this.today,
        },
        {
          label: this.$t('calendarButtons.week'),
          onClick: this.lastWeek,
        },
        {
          label: this.$t('calendarButtons.month'),
          onClick: this.lastMonth,
        },
      ]
    },
  },
  methods: {
    setToggledButton(label: any) {
      setTimeout(() => {
        this.toggled = label
      })
    },
    today() {
      this.dates = [new Date(new Date().setHours(0, 0, 0, 0)), new Date()]
    },
    yesterday() {
      const today = new Date()
      const yesterday = new Date(today.getTime() - DAY)
      this.dates = [
        new Date(yesterday.setHours(0, 0, 0, 0)),
        new Date(yesterday.setHours(23, 59, 59, 999)),
      ]
    },
    lastWeek() {
      const today = new Date(new Date().setHours(0, 0, 0, 0))
      this.dates = [new Date(today.getTime() - WEEK), today]
    },
    lastMonth() {
      const today = new Date()
      const milisecondsInCurrentMonth =
        new Date(today.setMonth(today.getMonth(), 0)).getDate() * DAY - DAY
      this.dates = [
        new Date(
          new Date(new Date().getTime() - milisecondsInCurrentMonth).setHours(
            0,
            0,
            0,
            0,
          ),
        ),
        new Date(),
      ]
    },
    clearDate() {
      this.dates = [new Date(), null]
      this.toggled = {}
    },
  },
})
