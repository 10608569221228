export const RUSSIAN_TRANSLATIONS = {
  taskTypes: {
    Prescription: 'Предписание',
  },
  button: {
    add: 'Добавить',
    back: 'Назад',
    settings: 'Настройки',
  },
  statuses: {
    board: 'Предписания',
    settings: 'Настройки: предписания',
  },
  error: 'Ошибка',
  columns: 'Отображать столбцы',
  overdue: 'Просрочено',
  emptyUserTaskList: 'Задачи отсутствуют',
  message: {
    unacceptableMove: 'Недопустимое действие',
  },
}
