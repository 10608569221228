import Vue from 'vue'
import {
  getIconColor,
  getIconClass,
  debounce,
  debounceFuncArr,
  debouncePromise,
} from '@/utils'
import { mapState } from 'vuex'
import {
  createCamerasConnection,
  getPermissionsByIdsMap,
  getPermittedScopes,
} from '@netvision/lib-api-gateway'
import { history, SearchParams } from '@netvision/lib-history'

let batchQueue = [] as { id: Uuid; type: string }[]
const getPermIds = debounceFuncArr(getPermissionsByIdsMap, 100)

export default Vue.extend({
  name: 'Mixins',
  data() {
    return {}
  },
  computed: {
    ...mapState(['idsMap', 'permissionScopes']),
  },
  methods: {
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : def
    },
    getIconColor,
    getIconClass,
    setCurrentTask(task: UserTask | {}) {
      const query = SearchParams.parse(history.location.search)
      if ('id' in task) {
        history.push({
          search: SearchParams.stringify({
            ...query,
            task: task.id,
          }),
        })
      }
    },
    canI(
      scope: string,
      entity: { id: string; resourceId?: string } | null = null,
    ) {
      const scopes = this.permissionScopes.get(entity ? entity.id : scope)
      if (scopes) {
        return Array.isArray(scopes) ? scopes.includes(scope) : false
      }
      this.fetchPermissions(scope, entity)
    },
    async fetchPermissions(
      scope: string,
      entity: { id: string; resourceId?: string } | null = null,
    ) {
      if (entity?.resourceId) {
        const permIds = (await getPermIds([entity.resourceId])) as Map<
          string,
          string[]
        >
        if (permIds?.size > 0) {
          this.$store.commit('setValue', [
            'permissionScopes',
            new Map([...this.permissionScopes, ...permIds]),
          ])
        }
      } else {
        const result = await debouncePromise(
          async () => await getPermittedScopes([scope]),
          1000,
          scope,
        )
        this.permissionScopes.set(scope, result)
      }
    },
    putInBatchQueue(ids: { id: Uuid; type: string }[]) {
      const newIds = ids.filter(
        (e) =>
          !this.idsMap[e.id] && !batchQueue.find(({ id }: any) => id === e.id),
      )
      if (newIds.length > 0) {
        batchQueue = [...batchQueue, ...newIds]
        debounce(
          async () => {
            try {
              if (batchQueue.length > 0) {
                const localBatch = [...batchQueue]
                batchQueue = []
                const {
                  results,
                }: {
                  results: {
                    id: Uuid
                    [k: string]: any
                  }[]
                } = await createCamerasConnection().v2.batchQuery(
                  {
                    entities: localBatch,
                  },
                  {
                    limit: 1000,
                    keyValues: true,
                  },
                )
                const idsMap = { ...this.idsMap } as any
                results.forEach((e) => {
                  idsMap[e.id] = e
                })
                this.$store.commit('setValue', ['idsMap', idsMap])
              }
            } catch (error) {
              console.error(error)
            }
          },
          200,
          'fetchBatch',
        )
      }
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
  },
})
