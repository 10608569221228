

































import Mixin from './common/Mixins'
import { history } from '@netvision/lib-history'
export default Mixin.extend({
  name: 'Settings',
  props: {
    steps: Array as () => Step[],
    stepsChecked: Object as () => Record<string, boolean>,
  },
  data() {
    return {}
  },
  methods: {
    saveInLocalStorage(stepsChecked: Record<string, boolean>) {
      localStorage.setItem(
        `savedStepsChecked${history.location.pathname}`,
        JSON.stringify(stepsChecked),
      )
    },
    dropCurrent() {
      const stepsChecked = this.steps.reduce((acc, { title, isHidden }) => {
        acc[title] = isHidden === undefined || !isHidden
        return acc
      }, {} as Record<string, boolean>)
      this.$emit('update:stepsChecked', stepsChecked)
      this.saveInLocalStorage(stepsChecked)
    },
  },
})
