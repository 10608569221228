

























import Mixins from './Mixins'
import { mapState } from 'vuex'

export default Mixins.extend({
  name: 'User',
  props: {
    userId: (String as unknown) as () => Uuid,
    showEmail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['idsMap']),
    user() {
      if (this.idsMap[this.userId || '']) {
        return this.idsMap[this.userId || '']
      } else {
        setTimeout(() => {
          this.userId &&
            this.putInBatchQueue([{ id: this.userId, type: 'User' }])
        })
        return {}
      }
    },
  },
})
