import { mapState } from 'vuex'
type Resourse = Uuid | Scope

const initialState = () => {
  return {
    taskTypes: {
      prescription: {
        title: 'prescription',
        icon: {
          color: '#FBA847',
          iconClass: 'mdi-close',
        },
      },
    } as Record<string, UserTaskType>,
    userTaskMetadata: {} as UserTaskMetadata,
    loading: true,
    idsMap: {} as Record<Uuid, any>,
    myId: undefined as Uuid[] | undefined,
    currentUserTask: null as UserTask | null,
    permissionScopes: new Map() as Map<Resourse, string[]>,
  } as Record<string, any>
}

type State = ReturnType<typeof initialState>

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] }
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export default {
  state: initialState,
  mutations: {
    setValue(state: Record<string, any>, [key, value]: [string, any]) {
      state[key] = value
    },
    reset(state: Record<string, any>) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
}
