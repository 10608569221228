var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-d-flex  p-mx-3 p-flex-column",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"kanban__header",style:({
      'border-bottom': _vm.loading
        ? '1px solid transparent'
        : '1px solid var(--text-color-secondary)',
    }),attrs:{"data-cy":"kanban-header"}},[_c('div',{staticClass:"p-d-flex p-jc-between  p-ai-center"},[_c('div',{staticClass:"kanban__title p-ai-center"},[_c('div',{staticClass:"p-d-flex p-ai-center",style:({
            height: 'calc(68rem / var(--bfs))',
            'border-bottom': _vm.loading
              ? '4px solid transparent'
              : '4px solid var(--primary-color)',
          })},[_c('span',{staticClass:"p-my-2",attrs:{"data-cy":"kanban-title"},domProps:{"textContent":_vm._s(_vm.title || _vm.$t(("statuses." + _vm.status)))}})]),(_vm.status === 'board')?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t('button.settings')),expression:"$t('button.settings')",modifiers:{"right":true}}],staticClass:"p-button-secondary p-button-outlined p-button-sm p-button-icon-only p-ml-3",attrs:{"data-cy":"settings-button","icon":"mdi mdi-18px mdi-cog"},on:{"click":function($event){return _vm.setHash('settings')}}}):_vm._e(),(_vm.status === 'settings')?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t('button.back')),expression:"$t('button.back')",modifiers:{"right":true}}],staticClass:"p-button-secondary p-button-outlined p-button-sm p-button-icon-only p-ml-3",attrs:{"data-cy":"settings-button-back","icon":"mdi mdi-18px mdi-arrow-left"},on:{"click":function($event){return _vm.setHash(null)}}}):_vm._e()],1),(_vm.canI('CreateUsersTask'))?_c('Button',{attrs:{"data-cy":"button-create-user-task","label":_vm.$t('button.add')},on:{"click":function () {}}}):_vm._e()],1),(_vm.loading)?_c('ProgressBar',{style:({
        height: 'calc(2rem / var(--bfs))',
      }),attrs:{"data-cy":"progressbar-line","mode":"indeterminate"}}):_c('div',{style:({
        height: 'calc(2rem / var(--bfs))',
      })})],1),(_vm.status === 'board')?_c('div',{staticClass:"p-d-flex all-width-height",attrs:{"data-cy":"kanban-table"}},[_c('div',{staticClass:"p-d-flex all-width-height"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.title,staticClass:"p-d-flex p-flex-column p-mb-3",style:({
          'max-width': '50%',
          width: ((100 / _vm.columns.length) + "%"),
          position: 'relative',
        }),attrs:{"data-cy":("column-" + (column.title))}},[_c('div',{staticClass:"p-d-flex p-flex-column"},[_c('div',{staticClass:"p-d-flex p-ai-center",attrs:{"data-cy":"column-header"}},[(!_vm.infiniteScrollColumnLoading[column.title])?_c('i',{staticClass:"p-mr-1 mdi mdi-24px",class:[
                column.icon
                  ? column.icon.iconClass || 'mdi-circle-medium'
                  : 'mdi-circle-medium' ],style:({
                color: column.icon
                  ? column.icon.color || '#3C72FF'
                  : '#3C72FF',
                textShadow: ((column.icon ? column.icon.color || '#3C72FF' : '#3C72FF') + " 0 0 15px"),
              }),attrs:{"data-cy":"column-icon"}}):_c('i',{staticClass:"p-mr-1 mdi mdi-24px mdi-loading mdi-spin",style:({
                color: column.icon
                  ? column.icon.color || '#3C72FF'
                  : '#3C72FF',
                textShadow: ((column.icon ? column.icon.color || '#3C72FF' : '#3C72FF') + " 0 0 15px"),
              })}),_c('span',{staticClass:"column-title",attrs:{"data-cy":("column-title-" + (column.title))},domProps:{"textContent":_vm._s(_vm.te(("enumOptions." + (column.title)), column.title))}})])]),_c('div',{staticClass:"tasks-column",attrs:{"data-cy":"tasks-list"},on:{"scroll":function($event){return _vm.onScroll($event, column.title)}}},[_c('draggable',{staticStyle:{"height":"calc(100% - 2rem)"},attrs:{"list":column.userTasks,"animation":200,"disabled":_vm.draggedEntity ? !_vm.canI(column.scope, _vm.draggedEntity) : false,"sort":true,"ghost-class":"ghost-card","group":"tasks"},on:{"start":function($event){return _vm.startDrag($event)},"end":function($event){_vm.draggedEntity = null},"add":function($event){return _vm.onDrop($event, column)}}},_vm._l((column.userTasks),function(task){return _c('TaskCard',{key:task.id,staticClass:"task",attrs:{"data-cy":"task-card","data-id":task.id,"task":task}})}),1)],1)])}),0),(!_vm.loading && _vm.allTasks.length === 0)?_c('EmptyTasks'):_vm._e()],1):_vm._e(),(_vm.status === 'settings')?_c('div',{staticClass:"p-d-flex",staticStyle:{"width":"100%","height":"100%","overflow":"hidden"}},[_c('Settings',{attrs:{"steps":_vm.steps,"stepsChecked":_vm.stepsChecked},on:{"update:stepsChecked":function($event){_vm.stepsChecked=$event},"update:steps-checked":function($event){_vm.stepsChecked=$event}}})],1):_vm._e(),(_vm.currentUserTask !== null)?_c('UserTaskDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }