




import Vue from 'vue'
import CalendarField from './FilterFields/CalendarField.vue'
export default Vue.extend({
  name: 'Kanban',
  props: {
    filters: Array as () => Filter[],
  },
  data() {
    return {
      filterMap: {
        datesPeriod: CalendarField,
      },
    }
  },
})
