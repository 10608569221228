export const insensitify = (string: string) => {
  return string
    .split('')
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        return char
      }
    })
    .join('')
}

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const timers = {} as Record<string, NodeJS.Timeout>

export const debounce = (
  callback: Function,
  timeout: number,
  key: string = 'main',
) => {
  clearTimeout(timers[key])
  // @ts-ignore
  timers[key] = setTimeout(callback, timeout)
}

export const debouncePromise = (
  callback: Function,
  timeout: number,
  key = 'default',
) => {
  return new Promise((resolve, reject) => {
    clearTimeout(timers[key])
    timers[key] = setTimeout(async () => {
      try {
        const result = await callback()
        resolve(result)
      } catch (error) {
        reject(error)
      }
    }, timeout)
  })
}

export const debounceFuncArr = (callback: Function, timeout: number) => {
  let timer = (0 as unknown) as NodeJS.Timeout
  let savedArr = [] as string[]
  return (arr: string[]) => {
    return new Promise((resolve) => {
      clearTimeout(timer)
      savedArr = Array.from(new Set([...savedArr, ...arr]))
      timer = setTimeout(async () => {
        const result = await callback(savedArr)
        savedArr = []
        resolve(result)
      }, timeout)
    })
  }
}

export const getIconColor = (
  entity: { icon?: { color?: string } },
  defaultColor = '#3C72FF',
) => {
  return entity.icon ? entity.icon.color || defaultColor : defaultColor
}

export const getIconClass = (
  entity: { icon?: { iconCLass?: string } },
  defaultIconClass = 'mdi-circle-medium',
) => {
  return entity.icon
    ? entity.icon.iconCLass || defaultIconClass
    : defaultIconClass
}

const formatter = new Intl.DateTimeFormat('ru', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

export const formatDate = (date: string | number | null) => {
  if (typeof date === 'number') {
    return formatter.format(new Date(date * 1000))
  } else {
    const parsedDate = Date.parse(date || '')
    return isNaN(parsedDate) ? '' : formatter.format(parsedDate)
  }
}

export const makeDictFromArray = <T extends {}, U extends keyof T>(
  arr: T[],
  key: U,
) => {
  return arr.reduce((acc, el) => {
    if (typeof el[key] === 'string') {
      // @ts-ignore
      acc[el[key]] = el
    }
    return acc
  }, {} as Record<string, T>)
}
